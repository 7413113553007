export default [
  {
    id: "1",
    code: "LAUNCHPROMO",
    discount_price: "60",
    date_created: "10-03-2021",
    expiring_date: "10-03-2021",
    use_count: 2,
  },
  {
    id: "2",
    code: "LAUNCHPROMO",
    discount_price: "60",
    date_created: "10-03-2021",
    expiring_date: "10-03-2021",
    use_count: 2,
  },
  {
    id: "3",
    code: "NOVMODEL",
    discount_price: "60",
    date_created: "10-03-2021",
    expiring_date: "10-03-2021",
    use_count: 1,
  },
];
