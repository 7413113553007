<template>
  <div>
    <grid-table :column-defs="columnDefs" :row-data="rowData"></grid-table>
  </div>
</template>

<script>
import active_coupons from "../db/active_coupons";
import GridTable from "../../../components/tables/GridTable";
import gridTableParentMixin from "../../../../mixins/gridTableParentMixin";
import axios from "../../../../axios";
export default {
  mixins: [gridTableParentMixin],
  name: "CouponHistory",
  components: { GridTable },
  data() {
    return {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Code",
          field: "code",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Discount Price (%)",
          field: "discount_price",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Date Created",
          field: "date_created",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Expiring Date",
          field: "expiring_date",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Use Count",
          field: "use_count",
          filter: true,
          sortable: true,
        },
      ],
      rowData: [],
      dataResourceUrl: `${this.$store.state.server.requestUri}/admin/coupons`,
    };
  },

  methods: {
    getTableData(resourceUrl) {
      const loading = this.$vs.loading();
      axios
        .get(`${resourceUrl}`)
        .then((res) => {
          this.rowData = res.data.data;
          loading.close();

          // this.closePageLoader();
        })
        .catch((err) => {
          loading.close();
          this.$toast(err.response.data.message, {
            type: "error",
            position: "top-center",
          });
          console.log(err.response);
        });
    },
  },

  created() {
    this.getTableData(`${this.dataResourceUrl}?${this.defaultQueryString}`);
    this.rowData = active_coupons;
  },
};
</script>

<style scoped></style>
